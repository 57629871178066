import store from '../store'

export function addExportToQueue(group, queryParameters) {
    //window.console.log("## helpers\sevices@exportData ")
    //window.console.log("Group: " + group)
    //window.console.log("Query parameters:")
    //window.console.log(queryParameters)

    store.commit('SET_PROGRESS', true)
    store.commit('SET_LOADER_TEXT', 'Izvoz podatkov je v teku...')
    store.commit('SET_LOADER', true)
    
    store.dispatch('EXPORT', {
        group: group,
        queryParameters: queryParameters
    })
    .then(response => {
        //window.console.log(response)

        store.commit('snackbarDisplay', false)
        //store.commit('snackbarText', "Export is added to queue. Wait to get your file soon.")
        store.commit('snackbarText', "Izvoz podatkov je v teku. Dokument z izvoženimi podatki najdete na seznamu na strani 'Izvoz podatkov'.")
        store.commit('snackbarMode', '')
        store.commit('snackbarTimeout', 7000)
        store.commit('snackbarMode', 'multi-line')
        store.commit('snackbarDisplay', true)
    
    })
    .catch(error => {
        window.console.log('EXPORT DATA error')
        window.console.log(error)
        store.commit('snackbarDisplay', false)
        store.commit('snackbarMode', 'multi-line')
        store.commit('snackbarText', 'Pri izvozu podatkov je prišlo do napake. Prosimo poskusite ponovno.')
        store.commit('snackbarMode', 'multi-line')
        store.commit('snackbarDisplay', true)
    })
    .finally(() => {
        setTimeout(() =>{
            store.commit('SET_PROGRESS', false)
            store.commit('SET_LOADER', false)
            store.commit('SET_LOADER_TEXT', '')
            store.commit('snackbarMode', '')
        },600)
    })
}



export default {
    addExportToQueue
}
